@tailwind base;
@tailwind components;

*{
  font-family: 'Terminal','New Gulim';
  @apply p-0 m-0 font-normal leading-normal text-3.75 lg:text-lg box-border shadow-none border-none decoration-0 no-underline bg-inherit;
}

.grecaptcha-badge {
  visibility: hidden;
}

button {
  @apply bg-transparent;
}

a {
  @apply cursor-pointer text-inherit;
}

h1 {
  @apply text-xl lg:text-6.5;
}
h2 {
  @apply text-xl lg:text-2xl;
}
h3 {
  @apply text-4.25 lg:text-5.5;
}
h4 {
  @apply text-base lg:text-xl;
}

@tailwind utilities;

@layer utilities {
}